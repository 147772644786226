<template>
  <div class="page">
    <div class="titleAbsolute" v-if="!yandex">
      <div class="title">Где нас найти?</div>
      <div class="openYandex" @click="yandex = true">Открыть yandex карты</div>
    </div>
    <div class="closeYandex" v-else  @click="yandex = false">
      Скрыть
    </div>

    <div class="yandexMap" v-if="yandex">
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac368a98922ad2332617c8d0add110e79bbec44c4379c94b60d34a96b99c06df3&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div class="contactInfo" v-if="!yandex">
      <div class="number">
        <a href="tel:+79219132222" class="phoneNumber">+7 (953) 349-33-80</a>
        <a href="mailto:izvarindima@gmail.com">Izvarindima@gmail.com</a>
      </div>
      <div class="street">
        <p>Адрес:</p>
        <p>191123, Санкт-Петербург,</p>
        <p>Гродненский пер., дом 14</p>
        <p>м. Чернышевская</p>
      </div>

    </div>
  </div>
</template>

<script>

export default {

  name: "contacts",
  data() {
    return {
      yandex: false,
    }
  },
  created() {
    document.title = 'IzvarinLaw - Контакты';
  }
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .page
    width: 100%
    display: flex
    background-image: url(../../public/img/map.png)
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
  .titleAbsolute
    position: absolute
    left: 0
    top: 0
    display: flex
    flex-direction: column
    padding: 60px 70px
    z-index: 10
    +r(998)
      padding: 30px
    +r(750)
      padding: 60px 30px
  .closeYandex
    color: #fff
    padding: 5px
    background-color: rgba(5,5,5, .7)
    position: absolute
    left: 50px
    font-size: 22px
    top: 10px
    z-index: 10
    +r(750)
      top: 70px
      font-size: 16px
  .title
    font-family: 'Montserrat', sans-serif
    font-size: 34px
    font-weight: 600
    margin: 0 0 17px
    +r(1400)
      font-size: 30px
    +r(998)
      font-size: 24px
    +r(750)
      font-size: 18px
  .openYandex
    cursor: pointer
    +r(998)
      font-size: 18px
    +r(750)
      font-size: 16px
    &:hover
      color: #DB6500
  .contactInfo
    position: absolute
    left: 70px
    bottom: 70px
    font-size: 22px
    font-weight: 400
    +r(1400)
      font-size: 18px
    +r(998)
      font-size: 16px
      left: 30px
      bottom: 70px
    p
      margin: 0
      padding: 0
      margin-bottom: 12px
      +r(1400)
        margin-bottom: 7px
      +r(650)
        margin-bottom: 5px
    .number
      margin-bottom: 40px
      display: flex
      flex-direction: column
      +r(1400)
        margin-bottom: 25px
      +r(750)
        margin-bottom: 20px
      +r(650)
        margin-bottom: 10px
      a
        color: #fff
        margin-bottom: 10px
        text-decoration: none
        +r(650)
          margin-bottom: 5px
    .street
      p:nth-child(1)
        font-size: 24px
        font-weight: 500
        margin-bottom: 20px
        +r(1400)
          font-size: 20px
        +r(998)
          font-size: 18px
        +r(650)
          margin-bottom: 10px

  .yandexMap
    width: 100%
    height: 100%
    position: absolute
    left: 0
    top: 0
</style>